import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { CursosDropdown } from "./curse-dropdown";
import { ModulesDropdown } from "./module-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

export function CourseModuleSection({
  selectedPairs,
  handleCursoChange,
  handleModuloChange,
  addSelection,
  onCreateClass,
  isLoading,
  concludedUpload,
  removeItem,
}) {
  const [timer, setTimer] = useState(45);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    if (!isButtonEnabled) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => {
          if (prevTime === 1) {
            clearInterval(countdown);
            setIsButtonEnabled(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [isButtonEnabled]);

  function isInitialPairFilled() {
    const firstPair = selectedPairs[0];
    return firstPair && firstPair.curso && firstPair.modulo;
  }

  function canRemoveItem() {
    return selectedPairs.length > 1;
  }

  function renderCourseModuleSelection(pair, index) {
    return (
      <>
        <CursosDropdown onChange={(e) => handleCursoChange(e, index)} />
        {pair.curso && (
          <ModulesDropdown
            onChange={(e) => handleModuloChange(e, index)}
            cursoId={pair.curso}
          />
        )}
      </>
    );
  }

  return (
    <div className="mt-5">
      <h3>Criar Aulas no Site</h3>
      {selectedPairs.map(renderCourseModuleSelection)}
      <Button
        onClick={removeItem}
        disabled={!canRemoveItem()}
        className={canRemoveItem() ? "btn-danger me-2" : "btn-secondary me-2"}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button
        onClick={addSelection}
        disabled={!isInitialPairFilled()}
        className="btn-success me-3"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Button
        onClick={onCreateClass}
        disabled={
          isLoading || !selectedPairs.length || !concludedUpload || !isButtonEnabled
        }
      >
        {isLoading ? (
          <div className="spinner-border" role="status"></div>
        ) : isButtonEnabled ? (
          selectedPairs.length === 1 ? "Criar aula no site" : "Criar aulas no site"
        ) : (
          `Aguarde ${timer}s`
        )}
      </Button>
    </div>
  );
}
